
import { defineComponent, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import BaseTexarea from "@/components/base/BaseTextarea.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import useDemandTemplateDetailsForm from "../useDemandTemplateDetailsForm";

export default defineComponent({
  name: "DemandTemplateDetailsSectionProfileGeneral",
  components: {
    DetailsSection,
    BaseTexarea
  },
  props: {
    demandTemplateId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // get demand template
    const {
      demandTemplate,
      onDemandTemplateResult,
      onUpdateDemandTemplate,
      onUpdateDemandTemplateSuccess,
      loading,
      error
    } = useDemandTemplateStore({ demandTemplateId: props.demandTemplateId });

    // set form parameters after each component rendering
    const demandTemplateName = computed(() => (demandTemplate.value?.name ? demandTemplate.value.name : ""));
    const demandTemplateDescription = computed(() =>
      demandTemplate.value?.description ? demandTemplate.value.description : ""
    );

    /**
     * form
     */
    const { formData, nameSchema, descriptionSchema, demandTemplateSchema, validate } = useDemandTemplateDetailsForm({
      name: demandTemplateName.value,
      description: demandTemplateDescription.value
    });

    // set form values to original graphql query values
    const resetFormValues = () => {
      formData.name = demandTemplateName.value;
      formData.description = demandTemplateDescription.value;
    };

    // set values after result is queried
    onDemandTemplateResult(() => {
      resetFormValues();
    });

    // check if form values have been updated
    const isFormUnchanged = computed(() => {
      if (formData.name === demandTemplateName.value && formData.description === demandTemplateDescription.value)
        return true;
      else return false;
    });

    // Validate and create demandTemplate
    const onSubmit = () => {
      // check if abbreviation is set
      demandTemplateSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onUpdateDemandTemplate(formData.name, formData.description);
        })
        .catch(() => {
          validate.value = true;
        });
    };

    onUpdateDemandTemplateSuccess(() => {
      console.info("demandTemplate updated");
    });

    return {
      formData,
      validate,
      demandTemplate,
      onSubmit,
      nameSchema,
      descriptionSchema,
      resetFormValues,
      isFormUnchanged,
      loading,
      error
    };
  }
});
