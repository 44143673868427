
import { defineComponent, computed } from "vue";
// components
import DemandTemplateDetailsSectionDetails from "./DemandTemplateDetailsSectionDetails.vue";
import DemandTemplateDetailsSectionDemand from "./DemandTemplateDetailsSectionDemand.vue";
import DemandTemplateDetailsSectionRecurrence from "./DemandTemplateDetailsSectionRecurrence.vue";
import DemandTemplateDeleteButton from "@/features/demandTemplates/DemandTemplateDeleteButton.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import { useRoute, useRouter } from "vue-router";
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "DemandTemplateDetailsPageSettings",
  components: {
    DemandTemplateDetailsSectionDetails,
    DemandTemplateDetailsSectionDemand,
    DemandTemplateDetailsSectionRecurrence,
    DemandTemplateDeleteButton
  },
  setup() {
    // get template id
    const {
      params: { demandTemplateId: demandTemplateUrlId }
    } = useRoute();
    const demandTemplateId = computed(() => repairUuid(demandTemplateUrlId));

    // get template
    const { demandTemplate } = useDemandTemplateStore({ demandTemplateId: demandTemplateId.value });

    // router for redirect after delete
    const router = useRouter();

    return { demandTemplateId, demandTemplate, router };
  }
});
