<template>
  <div class="w-full">
    <div class="flex flex-1 justify-between mb-1">
      <label class="block text-sm font-medium text-gray-700" :for="name">
        <span v-if="label !== ''">{{ label }}</span>
        <slot name="label"></slot>
      </label>
      <div class="text-sm text-gray-500">
        <slot name="hint"></slot>
      </div>
    </div>
    <div class="flex flex-col rounded-md" :class="widthClasses">
      <div class="relative shadow-sm inline-flex">
        <textarea
          class="block w-full rounded-md sm:text-sm"
          :class="[stateClasses]"
          :id="name"
          :name="name"
          v-bind="$attrs"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <!-- Error Icon -->
        <div v-show="displayError" class="absolute inset-y-0 right-0 pr-5 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <p class="mt-2 text-sm text-gray-500">{{ description }}</p>
    </div>
    <p class="mt-2 text-sm text-red-600" v-show="displayError">
      {{ validationError }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from "vue";
import { StringSchema, ValidationError } from "yup";

// modelValue: usage of v-model in custom components based on https://v3.vuejs.org/guide/component-basics.html#using-v-model-on-components
export default defineComponent({
  name: "BaseInput",
  props: {
    label: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    // only used for v-model binding, no actual prop
    modelValue: {
      type: [String, Number, Date],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      validator: (prop: string) => ["lg", "full"].includes(prop),
      default: "full"
    },
    validate: {
      type: Boolean,
      default: false
    },
    schema: { type: (Object as PropType<StringSchema>) || null, default: null }
  },
  setup(props) {
    /**
     * Validation
     */

    // validation error
    const validationError = ref<string | null>(null);
    // only show error if validation is active
    const displayError = computed(() => {
      return props.validate === true && validationError.value;
    });

    // validate input
    const validateInput = () => {
      props.schema
        .validate(props.modelValue)
        .then(() => {
          validationError.value = null;
        })
        .catch((err: ValidationError) => {
          validationError.value = err.errors[0];
        });
    };

    // watch for input changes to validate
    if (props.schema)
      watch(
        () => props.modelValue,
        () => {
          validateInput();
        }
      );

    // watch for validation variable changes (usually after click submit)
    // acticate / deactivate validation
    if (props.schema)
      watch(
        () => props.validate,
        validate => {
          if (validate) {
            validateInput();
          }
        }
      );

    /**
     * Styles
     */

    // color classes
    const defaultStateClasses = "focus:ring-blue-500 focus:border-blue-500 border-gray-300";
    const errorStateClasses =
      "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
    const stateClasses = computed(() => {
      return displayError.value ? errorStateClasses : defaultStateClasses;
    });

    // small input field for short inputs
    const widthClasses = computed(() => `max-w-${props.maxWidth}`);

    return {
      widthClasses,
      stateClasses,
      displayError,
      validationError
    };
  }
});
</script>
