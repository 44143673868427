
import { defineComponent, ref, watch, PropType, computed } from "vue";
import { StringSchema, ValidationError } from "yup";

// modelValue: usage of v-model in custom components based on https://v3.vuejs.org/guide/component-basics.html#using-v-model-on-components
export default defineComponent({
  name: "BaseInput",
  props: {
    label: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    // only used for v-model binding, no actual prop
    modelValue: {
      type: [String, Number, Date],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      validator: (prop: string) => ["lg", "full"].includes(prop),
      default: "full"
    },
    validate: {
      type: Boolean,
      default: false
    },
    schema: { type: (Object as PropType<StringSchema>) || null, default: null }
  },
  setup(props) {
    /**
     * Validation
     */

    // validation error
    const validationError = ref<string | null>(null);
    // only show error if validation is active
    const displayError = computed(() => {
      return props.validate === true && validationError.value;
    });

    // validate input
    const validateInput = () => {
      props.schema
        .validate(props.modelValue)
        .then(() => {
          validationError.value = null;
        })
        .catch((err: ValidationError) => {
          validationError.value = err.errors[0];
        });
    };

    // watch for input changes to validate
    if (props.schema)
      watch(
        () => props.modelValue,
        () => {
          validateInput();
        }
      );

    // watch for validation variable changes (usually after click submit)
    // acticate / deactivate validation
    if (props.schema)
      watch(
        () => props.validate,
        validate => {
          if (validate) {
            validateInput();
          }
        }
      );

    /**
     * Styles
     */

    // color classes
    const defaultStateClasses = "focus:ring-blue-500 focus:border-blue-500 border-gray-300";
    const errorStateClasses =
      "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
    const stateClasses = computed(() => {
      return displayError.value ? errorStateClasses : defaultStateClasses;
    });

    // small input field for short inputs
    const widthClasses = computed(() => `max-w-${props.maxWidth}`);

    return {
      widthClasses,
      stateClasses,
      displayError,
      validationError
    };
  }
});
