<template>
  <DetailsSection v-if="demandTemplate">
    <template #header>
      <div class="flex">
        <router-link :to="{ name: 'workAreaDemand' }" class="flex items-center p-0.5 mr-2">
          <div class="h-5 w-5 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 19l-7-7 7-7" />
            </svg>
          </div>
        </router-link>
        <p>Details</p>
      </div>
    </template>
    <template #body>
      <div class="mt-6 grid grid-cols-10 gap-6">
        <div class="col-span-10 md:col-span-4">
          <BaseInput v-model="formData.name" :schema="nameSchema" :validate="validate" :name="'name'" :label="'Name'" />
        </div>
      </div>
      <div class="mt-6 grid grid-cols-10 gap-6">
        <div class="col-span-10 md:col-span-6">
          <BaseTexarea
            v-model="formData.description"
            :schema="descriptionSchema"
            :validate="validate"
            :name="'description'"
            :label="'Description'"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-end">
        <BaseButton class="mr-3" :variant="'default'" :disabled="isFormUnchanged" @btn-click="resetFormValues"
          >Cancel</BaseButton
        >

        <BaseButton :variant="'primary'" :disabled="isFormUnchanged" :loading="loading" @btn-click="onSubmit"
          >Save</BaseButton
        >
      </div>
      <div v-if="error" class="text-red-600">{{ error }}</div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import BaseTexarea from "@/components/base/BaseTextarea.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import useDemandTemplateDetailsForm from "../useDemandTemplateDetailsForm";

export default defineComponent({
  name: "DemandTemplateDetailsSectionProfileGeneral",
  components: {
    DetailsSection,
    BaseTexarea
  },
  props: {
    demandTemplateId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // get demand template
    const {
      demandTemplate,
      onDemandTemplateResult,
      onUpdateDemandTemplate,
      onUpdateDemandTemplateSuccess,
      loading,
      error
    } = useDemandTemplateStore({ demandTemplateId: props.demandTemplateId });

    // set form parameters after each component rendering
    const demandTemplateName = computed(() => (demandTemplate.value?.name ? demandTemplate.value.name : ""));
    const demandTemplateDescription = computed(() =>
      demandTemplate.value?.description ? demandTemplate.value.description : ""
    );

    /**
     * form
     */
    const { formData, nameSchema, descriptionSchema, demandTemplateSchema, validate } = useDemandTemplateDetailsForm({
      name: demandTemplateName.value,
      description: demandTemplateDescription.value
    });

    // set form values to original graphql query values
    const resetFormValues = () => {
      formData.name = demandTemplateName.value;
      formData.description = demandTemplateDescription.value;
    };

    // set values after result is queried
    onDemandTemplateResult(() => {
      resetFormValues();
    });

    // check if form values have been updated
    const isFormUnchanged = computed(() => {
      if (formData.name === demandTemplateName.value && formData.description === demandTemplateDescription.value)
        return true;
      else return false;
    });

    // Validate and create demandTemplate
    const onSubmit = () => {
      // check if abbreviation is set
      demandTemplateSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onUpdateDemandTemplate(formData.name, formData.description);
        })
        .catch(() => {
          validate.value = true;
        });
    };

    onUpdateDemandTemplateSuccess(() => {
      console.info("demandTemplate updated");
    });

    return {
      formData,
      validate,
      demandTemplate,
      onSubmit,
      nameSchema,
      descriptionSchema,
      resetFormValues,
      isFormUnchanged,
      loading,
      error
    };
  }
});
</script>
