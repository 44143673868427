import { ref, reactive } from "vue";
import * as yup from "yup";

export interface DemandTemplateForm {
  name: string;
  description: string;
}

/**
 * DemandTemplate form validation
 * @param initialData Initial data when modifying demand template details
 */
export default function useDemandTemplateForm<T extends object>(initialData: T) {
  const validate = ref(false);

  /**
   * Base properties
   * Using yup to generate a validation schema
   * https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
   */

  const nameSchema = yup.string();
  const descriptionSchema = yup.string();

  /**
   * Schemas
   */
  const demandTemplateSchema = yup.object().shape({
    name: nameSchema,
    description: descriptionSchema
  });

  /**
   * Form data
   */
  // create reactive form state
  const formData = reactive({ ...initialData });

  function resetForm(data = initialData) {
    Object.assign(formData, data);
    validate.value = false;
  }

  return {
    formData,
    resetForm,
    validate,
    demandTemplateSchema,
    nameSchema,
    descriptionSchema
  };
}
