
import { defineComponent, ref, computed, watch } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import DemandForm from "@/features/demand/DemandForm.vue";
import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import useDemand, { createDemandFormInputFromDemandParent, DemandFormInput } from "@/features/demand/useDemand";

export default defineComponent({
  name: "DemandTemplateDetailsSectionDemand",
  components: {
    DetailsSection,
    DemandForm,
    DeleteConfirmationModal
  },
  props: {
    demandTemplateId: {
      type: String,
      required: true
    },
    workAreaId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // get demand parents
    const {
      demandParents,
      onCreateDemandParent,
      onCreateDemandParentSuccess,
      onDeleteDemandParent,
      onDeleteDemandParentSuccess,
      onUpdateDemandParent,
      onUpdateDemandParentSuccess,
      loading,
      error
    } = useDemandTemplateStore({ demandTemplateId: props.demandTemplateId, workAreaId: props.workAreaId });

    // convert demand parents to demand form input objects
    const initialDemandList = demandParents.value
      ? demandParents.value.map(demandParent => createDemandFormInputFromDemandParent(demandParent))
      : [];
    const demandList = ref<DemandFormInput[]>(initialDemandList);
    // update demand list when cache data changes
    watch(demandParents, curr => {
      if (curr) demandList.value = curr.map(demandParent => createDemandFormInputFromDemandParent(demandParent));
      else demandList.value = [];
    });

    // edit demand
    const onAlterDemandParent = (demand: DemandFormInput) => {
      const index = demandList.value.findIndex(d => d.id === demand.id);
      if (index !== -1) demandList.value[index] = demand;
    };

    /**
     ****************** CREATE ***********************
     */

    // create demand parent object with default data
    const { createDemandFormInput } = useDemand();

    // Validate and create demandTemplate
    const onAddDemandParent = () => {
      // create demand parent
      const defaultDemandParent = createDemandFormInput();
      // // execute api call
      onCreateDemandParent(defaultDemandParent.startTime, defaultDemandParent.endTime, defaultDemandParent.amount);
    };

    onCreateDemandParentSuccess(() => {
      console.info("demand parent created");
    });

    /**
     ****************** UPDATE ***********************
     */

    onUpdateDemandParentSuccess(() => {
      console.info("demandTemplate updated");
    });

    /**
     ****************** DELETE ***********************
     */

    /**
     * DELETE confirmation dialoge
     */

    // confirmation diaglog visibility
    const deleteConfirmationVisible = ref(false);
    // id of demand parent that is selected for deletion
    const demandParentIdDeleteSelection = ref<string | null>(null);
    // click delete button
    const onRemoveDemandParent = (demandParentId: string) => {
      demandParentIdDeleteSelection.value = demandParentId;
      deleteConfirmationVisible.value = true;
    };

    // confirmation dialog message
    const deleteDialogHeader = computed(() => `Delete demand`);
    const deleteDialogMessage = `Are you really sure you want to delete the selected demand?`;

    /**
     * DELETE confirmation dialoge actions
     */

    const onConfirmDelete = () => {
      onDeleteDemandParent(demandParentIdDeleteSelection.value);
    };

    const onCancelDelete = () => {
      deleteConfirmationVisible.value = false;
      // reset id of delete selection
      demandParentIdDeleteSelection.value = null;
    };

    onDeleteDemandParentSuccess(() => {
      deleteConfirmationVisible.value = false;
      // reset id of delete selection
      demandParentIdDeleteSelection.value = null;
      console.info("Demand parent deleted");
    });

    return {
      error,
      loading,
      // create
      onAddDemandParent,
      // update
      onUpdateDemandParent,
      // delete
      deleteConfirmationVisible,
      deleteDialogHeader,
      deleteDialogMessage,
      onConfirmDelete,
      onCancelDelete,
      onRemoveDemandParent,
      // other
      onAlterDemandParent,
      demandList
    };
  }
});
