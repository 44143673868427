<template>
  <DetailsSection v-if="patternList.length > 0" :hasFooter="false">
    <template #header>
      <div class="flex">
        <p>Recurrence</p>
      </div>
    </template>
    <template #body>
      <RecurrencePatternInput :recurrencePattern="patternList[0]" @pattern-selected="onAlterRecurrencePattern" />
      <div>
        <BaseButton class="mt-2" :loading="loading" @click="clickUpdateRecurrencePattern(patternList[0])"
          >Save</BaseButton
        >
      </div>

      <div v-if="error" class="text-red-600">{{ error }}</div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import RecurrencePatternInput from "@/features/recurrencePattern/RecurrencePatternInput.vue";
// import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";

// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
// other
import {
  RecurrencePatternFormInput,
  createRecurrencePatternFormInputFromRecurrencePattern
} from "@/features/recurrencePattern/useRecurrencePattern";

export default defineComponent({
  name: "DemandTemplateDetailsSectionRecurrencePattern",
  components: {
    DetailsSection,
    RecurrencePatternInput
  },
  props: {
    demandTemplateId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // store
    const {
      recurrencePatterns,
      onUpdateRecurrencePattern,
      onUpdateRecurrencePatternSuccess,
      error,
      loading
    } = useDemandTemplateStore({ demandTemplateId: props.demandTemplateId });

    // convert recurrence patterns to recurrence pattern form input objects
    const initialPatternList = recurrencePatterns.value
      ? recurrencePatterns.value.map(pattern => createRecurrencePatternFormInputFromRecurrencePattern(pattern))
      : [];
    const patternList = ref<RecurrencePatternFormInput[]>(initialPatternList);
    // update pattern list when cache data changes
    watch(recurrencePatterns, curr => {
      if (curr)
        patternList.value = curr.map(recurrencePattern =>
          createRecurrencePatternFormInputFromRecurrencePattern(recurrencePattern)
        );
      else patternList.value = [];
    });

    // edit pattern
    const onAlterRecurrencePattern = (pattern: RecurrencePatternFormInput) => {
      const index = patternList.value.findIndex(p => p.id === pattern.id);
      if (index !== -1) patternList.value[index] = pattern;
    };

    /**
     ****************** UPDATE ***********************
     */

    // Validate and create demandTemplate
    const clickUpdateRecurrencePattern = (pattern: RecurrencePatternFormInput) => {
      // execute api call
      onUpdateRecurrencePattern(pattern.id, pattern.byweekday);
    };

    onUpdateRecurrencePatternSuccess(() => {
      console.info("recurrence pattern updated");
    });

    return {
      // update
      clickUpdateRecurrencePattern,
      error,
      loading,
      // other
      onAlterRecurrencePattern,
      patternList
    };
  }
});
</script>
